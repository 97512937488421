import { useState } from 'react';
import { QueryClient, useQueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from 'react-router-dom'
import toast, { Toaster } from "react-hot-toast";
import { useMenuItems, makeQueryKeyString, isAuthenticated, ApiRoutes } from "./Common/Api" ;
import { log } from "./Common/Utils";
import { strings } from './Common/Consts';
import { HttpOptions } from "./Common/Types";
import { Global }  from './Common/Styles';
import BaseLayout from './App/BaseLayout';
import Footer from './App/Footer';
import Header from './App/Header';
import SnttcRouter from './App/SnttcRouter'; 
import { SnttcStateProvider, useSnttcStateContext } from './App/SnttcContext';



const localLogOverrule= false;

function App() {
  const queryClient = new QueryClient({
                                        defaultOptions: {
                                          queries: {
                                            refetchOnWindowFocus : false,
                                            refetchOnMount: false,
                                            refetchOnReconnect: false,
                                            retry: false,
                                            staleTime: 5 * 60 * 1000,
                                          }
                                        }

            });

  return (
    <QueryClientProvider client={queryClient} >
      <SnttcStateProvider>
        <Global />
          <Toaster />      
          <Main />
      </SnttcStateProvider>
    </QueryClientProvider>
  );
}


function Main()  {
  const { state } = useSnttcStateContext();
  let option : HttpOptions = { queryparams : { site:  process.env.REACT_APP_SITE}, debug: false, queryKey: 'MenuItems' } ;
  const { data, error, refetch  } = useMenuItems(option, state.webdebug);
  const [ wasAuthenticated, setWasAuthenticated]= useState(isAuthenticated());
  const queryClient = useQueryClient()

  if (data && data?.length>0) {
    sessionStorage.setItem(strings.app.menu.sessionStorageKey, JSON.stringify(data));
  }

  if (  ((wasAuthenticated === true) && (isAuthenticated()===false)) ||
        ((wasAuthenticated === false) && (isAuthenticated()===true))  ){
    log(localLogOverrule||state.webdebug,"App.tsx","Main", "Authentication changed going to refresh queries!"); 
    queryClient.resetQueries( { queryKey: [makeQueryKeyString(ApiRoutes.menu.menulist, option)] });
    refetch();
    if (isAuthenticated()===false) {
      log(localLogOverrule||true,"App.tsx","Main", "The GET errors here are normal as we are not authenticated!"); 
      sessionStorage.setItem(strings.app.menu.sessionStorageKey, JSON.stringify([]));
    }
    setWasAuthenticated(isAuthenticated());
  }
  
  
  if ( isAuthenticated() === true ) {
    if (error) {
      if (error.message.toLowerCase().includes("data is undefined")) {
        // normal error 
      } else {
        if (error.message.includes("Cannot read properties of undefined")) {
          toast.error(strings.generic.networkproblems );
        } else {
          toast.error("(M) " + error.message);
        }
      }
    }
  } else {
    // Not authenticated so errors are probably from there, don't bother 
  }

  log(localLogOverrule||state.webdebug,"App.tsx","Main", "starting browser with data length=" + (data? data.length : 'undefined')); 
  return (
    <>
      <BrowserRouter>
        <Header />
        <BaseLayout key={data?.length}>
          <SnttcRouter/>
        </BaseLayout>
        <Footer/> 
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen />
    </>
  );
}

export default App;

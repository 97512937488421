export const strings = {
  generic: {
    buttonLoadingText: " . . . ",
    buttonAdd: "Toevoegen",
    buttonEdit: "Wijzigen",
    buttonSave: "Bewaar",
    buttonCancel: "Annuleer",
    nodata: "Geen gevonden gegevens",
    LabelSpacer: ":\u00a0\u00a0",
    networkproblems: "Netwerk problemen?"
  },
  app: {
    login: {
      localstorageStam: "###snttc###stam###",
      localstorageVoornaam: "###snttc###v_naam###",
      localstoragePass: "###snttc###Pass###",
      localstorageRememberMe: "###snttc###remember###",
      welcome: 'Welkom ',
      buttonLogin: 'Login',
      buttonLogout: 'Logout',
      placeHolderStam: 'lidnummer',
      placeHolderPassword: 'paswoord',
      failureMessage: 'Login geweigerd, kijk uw gegevens na!',
      failureMessageEmpty: 'Vul uw gegevens in!',
      rememberme: "Onthoud me"
    },
    footer: {
      copyright: ' 2024 SNTTC.be'
    },
    menu: {
      sessionStorageKey: "###snttc###menu###"
    }
  },
  sponsor: {
    dank: "Wij danken onze partners en sponsors"
  },
  beheerleden: {
    checkActief: "Actief: ",
    lid: {
      naam: "Naam",
      voornaam: "Voornaam",
      sex: "Geslacht",
      rijksregister: "Rijksregister",
      rijksregisterplaceholder: "99.02.31.100.01",
      geboorte: "Geboren",
      geboorteplaceholder:"31/02/1999",
      straat: "Straat",
      nr: "Nr",
      nrplaceholder: "1",
      bus: "Bus",
      busplaceholder:"b", 
      postcode: "Postcode",
      postcodeplaceholder: "9100",
      stad: "Gemeente",
      stadplaceholder: "Sint-Niklaas",
      land: "Land",
      landplaceholder: "België",
      telefoon: "Telefoon",
      telefoonplaceholder:"03/1234567",
      GSM: "GSM",
      GSMplaceholder : "0491/234567",
      ouder1: "ouder1",
      ouder2: "ouder2",
      email: "Email",
      emailplaceholder: "jos.smos@email.be",
      actief: "Actief",
      barlid: "Barlid",
      bestuur: "Bestuur",
      trainer: "Trainer",
      jeugd: "Jeugd",
      recreant:"Recreant",
      senior: "Senior",
      veteraan:"Veteraan",
      snitt: "Snitt",
      vlg: "Vlg",
      remark:"Opmerking",
      updatedBy: "Aangepast door",
      updatedOn: "op",
    },
    ledenlijst: {
      clubgegevens: "Clubgegevens",
      inschrijvingen: "Inschrijvingen",
      toegangen: "Machtigingen",
    }
  },
  settings: {
    seizoen: {
      selectSeizoen: "Huidig seizoen",
    }
  }, 
  betalingen: {
    betalingen_titel: "Betalingen",
    afwijkende_titel: "Afwijkende",
    openstaande_titel: "Openstaande",
  }

}

/*
welcome: 'Welkom ',
buttonLogin: 'Login',
buttonLogout: 'Logout',
placeHolderStam: 'lidnummer',
placeHolderPassword: 'paswoord',
keep: 'Hou me ingelogd op dit apparaat'
},
error: {
noError: '',
loginFailed: 'Login mislukt!',
accessDenied: 'U heeft geen toegang tot deze pagina!',
notFound: 'De opgevraagde gegevens bestaan niet.',
forgotPassword: 'Paswoord vergeten?'
},
alts:{
google: 'google',
instagram: 'instagram',
},

links: {
facebook: 'https://www.facebook.com/SNTTC',
instagram: 'https://www.instagram.com/snttc.be',
}, 
},
comp: {
types: {
kvkt: 'Kvkt',
vttl: 'Vttl',
},
results: 'Uitslagen',
},
tapitest:{
placeholderUser: 'T-Api user',
placeholderPass: 'T-Api paswoord',
placeholderClubid: 'Club id',
placeholderDivisionId: 'Division id',
buttonGetDivisions: 'Get divisions',
buttonClubTeams: 'Get club teams',
buttonClubMatches: 'Get club matches',
buttonClubMembers: 'Get club members',
buttonSeasons: 'Get Seasons'
},
nieuws: {
meernieuws: 'Meer nieuws',
mindernieuws: 'Minder nieuws',
}

*/